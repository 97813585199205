import { useState } from 'react';
import PropTypes from 'prop-types';
import { toSlug } from '@medifind/router';
import { useRouter } from '@medifind/router';
import { LocationFilter, MagicFilter } from '@medifind/search-results';
import { Button } from '@medifind/shared-basic-components';
import { useMediaQueryUp } from '@medifind/utils';
import { logEventGA4 } from '@medifind/zustand';

const FindADoctorForm = ({ heading, classes = {}, showWrapper }) => {
  const router = useRouter();
  const [error, setError] = useState({});
  const [input, setInput] = useState();
  const mediumBreakpoint = useMediaQueryUp('lg');

  const handleSubmit = (value) => {
    if (!value) {
      setError({ ...error, input: 'Enter your search' });
    } else {
      if (typeof value === 'object') {
        navigateByCategory(value);
      } else {
        logEventGA4('search', { type: 'doctor-name' });
        router.history.push(`/find-a-doctor?isStarted=true&name=${value}`);
      }
      setError({});
    }
  };

  const navigateByCategory = (event) => {
    const { key, personId, name, value, display, codingType, link } = event;
    if (key === 'hint') {
      logEventGA4('search', { type: 'hint' });
      router.history.push(`/find-a-doctor?isStarted=true&name=${link}`);
    } else if (key === 'doctors') {
      const doctorSlug = toSlug(name);
      logEventGA4('search', { type: 'doctor' });
      return router.history.push(`/doctors/${doctorSlug}/${personId}`);
    } else if (key === 'conditions') {
      if (codingType === 'procedure') {
        const codingSlug = toSlug(display);
        logEventGA4('search', { type: 'procedure' });
        return router.history.push(`/procedures/${codingSlug}/${value}/doctors`);
      } else {
        const codingSlug = toSlug(display);
        logEventGA4('search', { type: 'condition' });
        return router.history.push(`/conditions/${codingSlug}/${value}/doctors`);
      }
    } else if (key === 'specialties') {
      const slug = value;
      logEventGA4('search', { type: 'specialty' });
      return router.history.push(`/specialty/${slug}`);
    }
  };

  const setLocationError = (err) => {
    setError({ ...error, location: err });
  };

  return (
    <div className={classes['formContainer']}>
      {heading}
      <div className={classes['formSeperator']} />
      <div className={classes['formField']}>
        <LocationFilter
          className={classes.formInput}
          noHeader={true}
          classes={{
            ...classes,
            menuWrapper: classes.formInputText,
            filter: classes.formInput,
            input: classes.formSecondaryInputField,
            inputWrapper: classes.formInputText,
          }}
          showWrapper={showWrapper}
          showGetLocationButton
          showClearButton
          placeholder="Enter a location"
          onError={setLocationError}
          disableErrorMessage
        />
        {error.location && <div className={classes['formError']}>{error.location}</div>}
      </div>
      <div className={classes['formSeperator']} />
      <div className={classes['formField']}>
        <MagicFilter
          id="find-a-doctor-form-magic-filter"
          showHeader={false}
          placeholder={'Enter a condition, specialty, or name'}
          classes={{
            ...classes,
            menuWrapper: classes.formInputText,
            filter: classes.formInput,
            input: classes.formInputField,
            menu: classes.formDropdown,
            inputWrapper: classes.formInputText,
          }}
          fullWidth={true}
          showWrapper={showWrapper}
          showIcon={false}
          activeFirstOption={true}
          fillInput={!mediumBreakpoint}
          searchFor={['conditions', 'specialties', 'doctors']}
          onEnter={(value) => handleSubmit(value)}
          onInputChange={(value) => setInput(value)}
          onChange={async (event) => {
            if (event) {
              navigateByCategory(event);
            }
          }}
          highlightedItem={(item) => setInput(item)}
        />
        {error.input && <div className={classes['formError']}>{error.input}</div>}
      </div>
      <Button
        classes={{ container: classes['formSubmitButton'] }}
        type="thin"
        color="red"
        label={'Search'}
        onClick={() => handleSubmit(input)}
      />
    </div>
  );
};

FindADoctorForm.propTypes = {
  heading: PropTypes.node,
  classes: PropTypes.object,
  placeholder: PropTypes.string,
  showWrapper: PropTypes.bool,
};

export default FindADoctorForm;
